import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import { transformData } from "_utils/external-storage-connection/transformResData";
import styled from "styled-components";

import ModalButton from "../Common/ModalButton";
import ModalContents from "../Common/ModalContents";
import { UnlinkModalProps } from "../types";

const UnlinkModal = (props: UnlinkModalProps) => {
  const { close, data, onConfirm } = props;
  const [inputValue, setInputValue] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const { bucket, id } = transformData(data);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value !== bucket) {
      setMessage(t("modal.unlink.message.wrongName"));
    } else {
      setMessage(t("modal.unlink.message.unlink"));
    }
  };

  const getMessageColor = () => {
    return inputValue !== bucket ? "system_error" : "primary_main";
  };

  const handleBtnState = () => {
    return inputValue !== bucket ? true : false;
  };

  return (
    <S.Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <ModalContents title={t("modal.unlink.title")} />

        <Typography
          variant="body.100"
          color="text_10"
          style={{ userSelect: "text", cursor: "text" }}
        >
          {bucket}
        </Typography>
      </div>

      <Input
        style={{ marginBottom: "12px" }}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={t("modal.unlink.placeholder")}
      />

      <S.Caption>
        <Typography variant="caption.100" color={getMessageColor()}>
          {message}
        </Typography>
      </S.Caption>

      <ModalButton
        onConfirm={() => onConfirm(id)}
        onCancel={close}
        cancelText={t("modal.cancel")}
        confirmText={t("modal.confirm")}
        disabled={handleBtnState()}
      />
    </S.Modal>
  );
};

export default UnlinkModal;

const S = {
  Modal: styled.div({
    width: "564px",
    padding: "32px",
  }),
  Caption: styled.div({
    margin: "12px 0px 28px 0px",
  }),
};
