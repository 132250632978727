import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "_components/Input/Input";
import Textarea from "_components/Textarea";
import { updateS3BucketInfo } from "_libs/fetcher/external-storage";
import { transformData } from "_utils/external-storage-connection/transformResData";
import styled from "styled-components";

import ModalButton from "../Common/ModalButton";

const InfoEditModal = (props) => {
  const { close, onConfirm, placeholder, data } = props;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const { id } = transformData(data);
  const { t } = useTranslation();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescChange = (e) => {
    setDesc(e.target.value);
  };

  const handleEditInfo = async (s3Id: any, name, desc) => {
    const updatedData = {
      s3Alias: name,
      s3Description: desc,
    };
    const result = await updateS3BucketInfo(s3Id, updatedData);

    return result;
  };

  return (
    <S.Modal>
      <Input
        label={t("modal.infoEdit.name")}
        // required
        style={{ marginBottom: "28px" }}
        value={name}
        onChange={handleNameChange}
        placeholder={placeholder}
      />

      <Textarea
        label={t("modal.infoEdit.description")}
        style={{ height: "128px" }}
        maxLength={100}
        value={desc}
        onChange={handleDescChange}
      />
      <S.Button>
        <ModalButton
          onConfirm={() => handleEditInfo(id, name, desc)}
          onCancel={close}
          cancelText={t("modal.cancel")}
          confirmText={t("modal.confirm")}
        />
      </S.Button>
    </S.Modal>
  );
};

export default InfoEditModal;

const S = {
  Modal: styled.div({
    width: "554px",
    padding: "20px",
  }),
  Button: styled.div({
    marginTop: "28px",
  }),
};
