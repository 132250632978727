import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import styled from "styled-components";

import External from "./External";

const FileSelectModal = (props) => {
  const { t } = useTranslation();

  const { title, close, detail, onConfirm } = props;

  const { open } = useSnackBar();
  const [type, setType] = useState("external");

  const onSelectType = (type) => {
    setType(type);
  };

  const handleSubmit = (form) => {
    // TODO : 업로드 버튼 활성화 조건 추가

    form.preventDefault();

    // console.log(form);

    open({
      variant: "success",
      text: t("modal.fileSelect.message.uploadSuccess"),
    });

    close();
  };

  return (
    <S.Container>
      <Typography
        color="text_5"
        variant="subtitle.200_sb"
        style={{ marginBottom: "16px" }}
      >
        {title}
      </Typography>

      <External />

      <S.Submit>
        <Button variant="gray2" size="sm" width="100px" onClick={close}>
          {t("modal.cancel")}
        </Button>
        <Button
          variant="default"
          size="sm"
          width="100px"
          type="submit"
          onClick={(form) => handleSubmit(form)}
        >
          {t("modal.fileSelect.upload")}
        </Button>
      </S.Submit>
    </S.Container>
  );
};

const S = {
  Container: styled.form`
    display: flex;
    flex-direction: column;
    width: 557px;
    padding: 28px;
  `,
  Buttons: styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 28px;
  `,
  Submit: styled.div`
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  `,
};

export default FileSelectModal;
