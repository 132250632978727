export const transformData = (data: any) => {
  return {
    state: data?.state || "-",
    bucket: data?.bucket || data?.s3Bucket || "-",
    description:
      data?.description || data?.s3Description || data?.gcsDescription || "-",
    createdAt: data?.createdAt || data?.s3CreatedAt || "-",
    createdBy: data?.userEmail || data?.s3UserId || "-",
    externalId: data?.externalId || data?.s3AccessKey || "-",
    alias: data?.alias || data?.s3Alias || data?.gcsAlias || "-",
    prefix: data?.prefix || data?.s3Prefix || "",
    id: data?.ociId || data?.s3Id || data?.gcsId || "-",
    region: data?.region || data?.s3Region || "-",
    storageClass: data?.s3StorageClass || "-",
    arn: data?.s3Arn || "-",
  };
};

export const transformFilesData = (files: any) => {
  return {
    name: files?.name || "-",
    type: files?.type || "-",
    size: files?.size || "-",
    lastModified: files?.lastModified || files?.timeCreated || "-",
  };
};
