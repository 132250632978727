import { useState } from "react";
import Input from "_components/Input/Input";
import styled from "styled-components";

import ModalButton from "../Common/ModalButton";
import ModalContents from "../Common/ModalContents";
// import { InputModalProps } from "../types";

const InputModal = (props) => {
  const { close, title, onConfirm, placeholder } = props;

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <S.Modal>
      <ModalContents title={title} />

      <Input
        style={{ marginBottom: "32px", padding: "0px 58px" }}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />

      <ModalButton
        onConfirm={() => onConfirm(inputValue)}
        onCancel={close}
        cancelText="취소"
        confirmText="확인"
      />
    </S.Modal>
  );
};

export default InputModal;

const S = {
  Modal: styled.div({
    width: "464px",
    padding: "32px 0px",
  }),
};
