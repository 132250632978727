import React from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "_assets/icons/ic_check_default_white.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import { formatDate } from "_utils/date";
import styled from "styled-components";

const StateTextColor = (state: string) => {
  if (state === "대기") return "text_10";
  if (state === "확정") return "primary_main";
  return "text_10";
};

const ConsultingInfoModal = (props) => {
  const { close, variant, onConfirm, onCancel, data } = props;
  const { t, i18n } = useTranslation();

  const variantObject = {
    default: {
      title: t("modal.consulting.default.title"),
      description: t("modal.consulting.default.description"),
      button: {
        cancel: t("modal.consulting.default.button.cancel"),
        confirm: t("modal.consulting.default.button.confirm"),
      },
    },
    success: {
      title: t("modal.consulting.success.title"),
      description: t("modal.consulting.success.description"),
      button: {
        cancel: t("modal.consulting.success.button.cancel"),
        confirm: t("modal.consulting.success.button.confirm"),
      },
    },
  };

  const StateContent = {
    대기: t("modal.consulting.waiting"),
    확정: t("modal.consulting.confirmed"),
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    close();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  return (
    <S.ModalContainer>
      <S.Header>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <SVG
            src={CheckIcon}
            width={24}
            height={24}
            stroke="#2E90FA"
            strokeWidth={2}
          />

          <Typography variant="subtitle.100_sb" color="text_5">
            {variantObject[variant].title}
          </Typography>
        </div>
        <Typography variant="body.200" color="text_10">
          {variantObject[variant].description}
        </Typography>
      </S.Header>

      <S.Divider />

      <S.InfoContainer>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            {t("modal.consulting.name")}
          </Typography>
          <Typography variant="body.200" color="text_5">
            {data.consultant}
          </Typography>
        </S.InfoRow>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            {t("modal.consulting.consultingDate")}
          </Typography>
          <Typography variant="body.200" color="text_5">
            {formatDate(data.consultingDate, i18n.language)}
          </Typography>
        </S.InfoRow>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            {t("modal.consulting.consultingTime")}
          </Typography>
          <Typography variant="body.200" color="text_5">
            {data.consultingTime.map((time) => time.split(",")[0]).join(" ~ ")}
          </Typography>
        </S.InfoRow>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            {t("modal.consulting.meetingMethod")}
          </Typography>
          <Typography variant="body.200" color="text_5">
            {data.meetingMethod === "offline"
              ? t("modal.consulting.offline")
              : t("modal.consulting.online")}
          </Typography>
        </S.InfoRow>

        {data.state && (
          <S.InfoRow>
            <Typography variant="body.200" color="text_10">
              {t("modal.consulting.state")}
            </Typography>
            <Typography variant={"body.200"} color={StateTextColor(data.state)}>
              {StateContent[data.state]}
            </Typography>
          </S.InfoRow>
        )}
      </S.InfoContainer>

      <S.ButtonContainer>
        <Button
          onClick={handleCancel}
          variant="grayline"
          size="md"
          width="100%"
        >
          <Typography variant="body.200" color="text_10">
            {variantObject[variant].button.cancel}
          </Typography>
        </Button>
        <Button
          onClick={handleConfirm}
          variant="default"
          size="md"
          width="100%"
        >
          <Typography variant="body.200" color="white">
            {variantObject[variant].button.confirm}
          </Typography>
        </Button>
      </S.ButtonContainer>
    </S.ModalContainer>
  );
};

export default ConsultingInfoModal;

const S = {
  ModalContainer: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 468px;

    width: 468px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 24px;
    border-radius: 8px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Header: styled.div`
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Title: styled.h2`
    color: var(--text_2);
  `,
  Divider: styled.hr`
    height: 1px;
    background-color: var(--line_10);
    margin: 20px 0;
    border: none;
  `,
  InfoContainer: styled.div`
    background-color: var(--card_up);
    border-radius: 8px;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 28px;
  `,
  InfoRow: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  InfoText: styled.p`
    color: var(--text_5);
  `,
  ButtonContainer: styled.div`
    display: flex;
    gap: 16px;
  `,
};
