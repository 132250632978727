import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import { icons, illust } from "_styles/shared/icons";
import { getExtensionIcon } from "_utils/common";
import Image from "next/image";
import styled from "styled-components";

import Empty from "./Empty";

const externals = [
  {
    key: "object-storage",
    label: "OCI Cloud",
    icon: illust.oci[20],
  },
  {
    key: "S3",
    label: "AWS",
    icon: illust.aws[20],
  },
  {
    key: "google",
    label: "Google Cloud",
    icon: illust.google[20],
  },
  {
    key: "azure",
    label: "Azure",
    icon: illust.azure[20],
  },
];

const objects = [
  {
    type: "zip",
    key: "레오.zip",
    label: "레오.zip",
    size: 0,
  },
  {
    type: "folder",
    key: "아타드/",
    label: "아타드",
    size: 0,
  },
  {
    type: "folder",
    key: "아타드/팀원 정보/",
    label: "팀원 정보",
    size: 0,
  },
  {
    type: "folder",
    key: "아타드 주식회사/",
    label: "아타드 주식회사",
    size: 0,
  },
  {
    type: "folder",
    key: "아타드 주식회사/2024 결산 자료/",
    label: "2024 결산 자료",
    size: 0,
  },
  {
    type: "zip",
    key: "아타드 주식회사/2024 결산 자료/data.zip",
    label: "data.zip",
    size: 0,
  },
  {
    type: "jpg",
    key: "테스트.jpg",
    label: "테스트.jpg",
    size: 0,
  },
];

const External = () => {
  // TODO : 연결된 외부 스토리지 API

  const { open: onSnackbar } = useSnackBar();
  const [selected, setSelected] = useState({
    key: "object-storage",
    label: "OCI Cloud",
  });
  const [isSelect, setIsSelect] = useState(false);

  const { control, setValue } = useForm({
    defaultValues: {
      selectExternalItems: [],
      selectedFile: [],
    },
  });

  const {
    fields: selectExternalItems,
    append: selectExternalItemAppend,
    remove: selectExternalItemRemove,
  } = useFieldArray({
    control,
    name: "selectExternalItems",
  });

  const {
    fields: selectedFiles,
    append: selectedFileAppend,
    remove: selectedFileRemove,
  } = useFieldArray({
    control,
    name: "selectedFile",
  });

  const handleSelectExternal = (type) => {
    setSelected(type);
    selectedFileRemove();
  };

  const handleSelectExternalFile = (file, i) => {
    if (file.type === "folder") return;

    // field remove 로직
    for (const [i, field] of selectExternalItems.entries() as any) {
      if (field.key === file.key) return selectExternalItemRemove(i);
    }

    selectExternalItemAppend(file);
  };

  const handleMoveToFile = () => {
    // 선택한 파일이 selectedFile에 이미 존재한다면 삽입 불가
    const isExistlncudesFile = selectedFiles.some((item) =>
      selectExternalItems.some((selcetedItem) => selcetedItem.key === item.key),
    );

    if (isExistlncudesFile) {
      return onSnackbar({
        variant: "error",
        text: "이미 선택한 파일이 포함되어 있습니다.",
      });
    }

    selectedFileAppend(selectExternalItems);
    setValue("selectExternalItems", []);
  };

  useEffect(() => {
    if (selectExternalItems.length === 0) setIsSelect(false);
    if (selectExternalItems.length > 0) setIsSelect(true);
  }, [selectExternalItems]);

  return (
    <>
      <S.Buttons>
        {externals.map((external) => (
          <Button
            key={external.key}
            type="button"
            variant="grayline"
            size="md"
            startIcon={
              <Image src={external.icon} alt="icon" width={16} height={16} />
            }
            onClick={() => handleSelectExternal(external)}
            selected={selected.key === external.key}
          >
            {external.label}
          </Button>
        ))}
      </S.Buttons>

      {/* TODO :서버측 객체 구조에 따라 구성 변경 에정 */}
      {/* external contents */}
      <S.ContentsContainer>
        <S.ContentsWrapper>
          <S.Contents>
            <S.ExternalItems>
              {objects.map((object, i) => (
                <Controller
                  key={i}
                  name={`selectExternalItems.${i}`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <S.ExternalItem
                        onClick={() => handleSelectExternalFile(object, i)}
                        ml={`${(object.key.includes("/") && object.key.split("/").length - 2) * 28}px`}
                        bg={selectExternalItems.some(
                          (field) => field.key === object.key,
                        )}
                      >
                        {object.type === "folder" && (
                          <Image
                            src={icons.chevron.right.bgray400_12}
                            alt=""
                            width={12}
                            height={12}
                            style={{ padding: "8px" }}
                          />
                        )}

                        <Image
                          src={getExtensionIcon(object.type)}
                          alt=""
                          width={16}
                          height={16}
                          style={{ padding: "8px" }}
                        />
                        <Typography variant="body.300" color="text_5">
                          {object.label}
                        </Typography>
                      </S.ExternalItem>
                    );
                  }}
                />
              ))}
            </S.ExternalItems>
          </S.Contents>
        </S.ContentsWrapper>
      </S.ContentsContainer>

      {externals.length === 0 && <Empty />}
    </>
  );
};

const S = {
  Buttons: styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 28px;
  `,
  ContentsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
  `,
  ContentsWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Contents: styled.div`
    padding: 16px;
    height: 400px;
    max-height: 400px;
    overflow: auto;
    border: 1px solid var(--line_10);
    border-radius: 8px;
  `,
  ExternalItems: styled.div`
    //
  `,
  ExternalItem: styled.div<{ ml: string; bg: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-left: ${({ ml }) => ml};
    cursor: pointer;
    border-radius: 4px;
    :hover {
      background-color: var(--background_default2);
    }
    background-color: ${({ bg }) => (bg ? "var(--primary_main_10dp)" : "")};
  `,
};

export default External;
