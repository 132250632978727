import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "_components/Input/Input";
import { createS3Folder } from "_libs/fetcher/external-storage";
import { transformData } from "_utils/external-storage-connection/transformResData";
import styled from "styled-components";

import ModalButton from "../Common/ModalButton";
import ModalContents from "../Common/ModalContents";

const CreateFolderModal = (props) => {
  const { t } = useTranslation();
  const { close, title, onConfirm, placeholder, data } = props;

  const [inputValue, setInputValue] = useState("");
  const { id, prefix } = transformData(data);

  const savedPrefix = sessionStorage.getItem("clickedPrefix") || "";

  useEffect(() => {
    setInputValue(""); // prefix가 바뀔 때마다 입력값 초기화
  }, [prefix]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCreate = async () => {
    const requestData = {
      s3Id: id,
      prefix: savedPrefix,
      folderName: inputValue,
    };

    try {
      const responseData = await createS3Folder(requestData);
      if (responseData && onConfirm) onConfirm(responseData.success);
    } catch (error) {
      console.error("폴더 생성 오류:", error);
    }
  };

  return (
    <S.Modal>
      <ModalContents title={title} />

      <Input
        style={{ marginBottom: "32px", padding: "0px 58px" }}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />

      <ModalButton
        onConfirm={() => handleCreate()}
        onCancel={close}
        cancelText={t("modal.cancel")}
        confirmText={t("modal.confirm")}
      />
    </S.Modal>
  );
};

export default CreateFolderModal;

const S = {
  Modal: styled.div({
    width: "464px",
    padding: "32px 0px",
  }),
};
